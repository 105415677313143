import { AppLoader } from 'components/AppLoader';
import { selectSelectedStudent, setSelectedStudentId } from 'features/shoppingSlice';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { Student } from 'types';
import { StudentItem } from '../StudentItem';

interface StudentListCardProps {
  isLoadingAuth: boolean;
  isLoadingData: boolean;
  errorLoadingData: boolean;
  studentList: Array<Student>;
  onEditClick?: (student: Student) => void;
  onDeleteClick: (student: Student) => void;
}

export const StudentListCard = ({
  isLoadingAuth,
  isLoadingData,
  errorLoadingData,
  studentList,
  onEditClick,
  onDeleteClick,
}: StudentListCardProps) => {
  const dispatch = useAppDispatch();
  const selectedStudent = useAppSelector(selectSelectedStudent);

  const onSelectStudent = useCallback((studentId: string) => dispatch(setSelectedStudentId(studentId)), [dispatch]);

  const studentListCardBody = useMemo(() => {
    if (isLoadingAuth || isLoadingData) {
      return <AppLoader />;
    }

    if (errorLoadingData) {
      return <span className="m-0 p-0 text-danger">Erro carregando lista de alunos associados a esta conta</span>;
    }

    if (studentList.length === 0) {
      return <span className="m-0 p-0 text-black">Não existem alunos associados a esta conta</span>;
    }

    return (
      <ul className="m-0 p-0 text-black">
        {studentList.map((student) => {
          let incompleteStudentData = false;

          if (student.disableOnlyBiography) {
            incompleteStudentData = !student.photoUrl;
          }

          if (!student.disableBiographyAndPhoto && !student.disableOnlyBiography) {
            incompleteStudentData = !student.biography || !student.photoUrl;
          }

          return (
            <StudentItem
              key={student.id}
              student={student}
              selectedStudentId={selectedStudent?.id ?? ''}
              incompleteRegistration={incompleteStudentData}
              hideEditButton={student?.disableBiographyAndPhoto ?? false}
              onSelectStudent={onSelectStudent}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          );
        })}
      </ul>
    );
  }, [
    isLoadingAuth,
    isLoadingData,
    errorLoadingData,
    studentList,
    selectedStudent,
    onSelectStudent,
    onEditClick,
    onDeleteClick,
  ]);

  return (
    <Card className="card-students">
      <Card.Header as="h5" className="bg-warning text-black border-0">
        Selecione um aluno para aquisição de livros
      </Card.Header>
      <Card.Body>{studentListCardBody}</Card.Body>
    </Card>
  );
};
