import { firebaseFunctionsService } from 'services/firebase/functions';
import { restApiService } from 'services/rest';

export interface StudentDataResult {
  id: string;
  studentCode: string;
  name: string;
  fullName: string;
  classCode: string;
}

interface getStudentByCodeResult {
  schoolCode: string;
  schoolName: string;
  studentData: StudentDataResult | null;
  disableBiographyAndPhoto: boolean;
  disableOnlyBiography: boolean;
}

interface registerCustomerResult {
  customerProviderId: string;
}

export interface CepQueryResult {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro?: string;
}

export interface CreditCardData {
  holderName: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  ccv: string;
}
interface paymentResult {
  paymentId: string;
  pix?: {
    success: string;
    encodedImage: string;
    payload: string;
    expirationDate: Date | null;
  };
}

export interface IFunctionsService {
  getStudentByCode: (studentCode: string) => Promise<getStudentByCodeResult>;
  registerCustomer: (
    fullName: string,
    email: string,
    cpf: string,
    mobilePhone: string,
    cep: string,
    adressNumber: string
  ) => Promise<registerCustomerResult>;
  getAddressByCep: (cep: string) => Promise<CepQueryResult>;
  paymentCreditCard: (
    totalValue: number,
    description: string,
    discountValue: number,
    creditCardData: CreditCardData,
    installmentCount?: number
  ) => Promise<paymentResult>;
  paymentPix: (totalValue: number, description: string, discountValue: number) => Promise<paymentResult>;
}

const getStudentByCode = async (studentCode: string): Promise<getStudentByCodeResult> => {
  const result = await firebaseFunctionsService.getStudentByCode(studentCode);
  return result.data as getStudentByCodeResult;
};

const registerCustomer = async (
  fullName: string,
  email: string,
  cpf: string,
  mobilePhone: string,
  cep: string,
  adressNumber: string
): Promise<registerCustomerResult> => {
  const result = await firebaseFunctionsService.registerCustomer(fullName, email, cpf, mobilePhone, cep, adressNumber);
  return result.data as registerCustomerResult;
};

const getAddressByCep = (cep: string): Promise<CepQueryResult> => restApiService.getAddressByCep(cep);

const paymentCreditCard = async (
  totalValue: number,
  description: string,
  discountValue: number,
  creditCardData: CreditCardData,
  installmentCount?: number
): Promise<paymentResult> => {
  const result = await firebaseFunctionsService.paymentCreditCard(
    totalValue,
    description,
    discountValue,
    creditCardData,
    installmentCount
  );

  return result.data as paymentResult;
};

export const paymentPix = async (
  totalValue: number,
  description: string,
  discountValue: number
): Promise<paymentResult> => {
  const result = await firebaseFunctionsService.paymentPix(totalValue, description, discountValue);

  return result.data as paymentResult;
};

export const functionsService: IFunctionsService = {
  getStudentByCode,
  registerCustomer,
  getAddressByCep,
  paymentCreditCard,
  paymentPix,
};
