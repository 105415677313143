import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import { ROUTES } from 'constants/';
import guidoSuccess from 'assets/images/guido_success.svg';

import './styles.scss';

interface SuccessPaymentProps {
  paymentId?: string;
}

export const SuccessPayment = ({ paymentId }: SuccessPaymentProps) => {
  const navigate = useNavigate();

  return (
    <Container className="text-center py-2 px-15">
      <Row className="align-items-center mb-4">
        <Col xs={12} md={6}>
          <img src={guidoSuccess} alt="Pinguim festejando" className="p-0 m-0 mb-4 success-image" />
        </Col>
        <Col xs={12} md={6}>
          <Container className="p-0 m-0 text-center text-md-start">
            <Row className="align-items-center mb-4">
              <Col>
                <h1 className="fw-bold p-0 m-0 mb-2">Parabéns!</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 className="p-0 m-0 mb-2">
                  <span className="fw-bolder">Seu pagamento foi confirmado!</span>
                </h4>
              </Col>
            </Row>
            <h5 className="p-0 m-0 mb-4 fs-5">
              Agora é só aguardar o recebimento do livro do seu autor preferido{' '}
              <span className="fw-bolder">NA ESCOLA</span>!
            </h5>
            <h5 className="p-0 m-0 mb-4 fs-5">
              Caso precisemos entrar em contato, vamos utilizar o email registrado em seu cadastro. Portanto, fique
              ligado para não perder nenhum email nosso!
            </h5>
            <h5 className="p-0 m-0">
              Código do pagamento:{' '}
              <span className="fw-bolder">{paymentId?.substring(paymentId?.indexOf('_') + 1)}</span>
            </h5>
          </Container>
        </Col>
      </Row>

      <Button className="my-2 text-white" onClick={() => navigate(ROUTES.HOME)}>
        <FontAwesomeIcon icon={faHome} className="me-2" /> Voltar para a página principal
      </Button>
    </Container>
  );
};
