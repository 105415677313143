import {
  getDatabase,
  DatabaseReference,
  ref,
  Query,
  query,
  orderByChild,
  equalTo,
  onValue,
  DataSnapshot,
  update,
  get,
} from 'firebase/database';
import { Student } from 'types';
import { BASE_DATABASE_URL } from '..';
import { initFirebase } from 'services/firebase/initFirebase';

initFirebase();
const database = getDatabase();

export const listenStudentList = (
  uid: string,
  callback: (studentDataList: Array<Student>) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => {
  if (!uid?.trim?.()) {
    if (errorCallback) {
      errorCallback(new Error('invalid-uid'));
    }

    return () => null;
  }

  const studentListRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/studentList`);
  // const studentQueryListRef: Query = query(studentListRef, orderByChild('parentUid'), equalTo(uid));
  const studentQueryListRef: Query = query(studentListRef, orderByChild(uid), equalTo(uid));

  const unsubscribe = onValue(
    studentQueryListRef,
    (snapshot: DataSnapshot) => {
      const studentList: Array<Student> = [];

      try {
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();

          const studentData: Student = {
            id: childSnapshot.key ?? '',
            parentUid: uid,
            schoolCode: data['schoolCode'] ?? '',
            schoolName: data['schoolName'] ?? '',
            studentCode: data['studentCode'] ?? '',
            studentName: data['studentName'] ?? '',
            studentFullname: data['studentFullname'] ?? '',
            studentClass: data['studentClass'] ?? '',
            biography: data['biography'] ?? '',
            imagePermission: data['imagePermission'] ?? false,
            photoUrl: data['photoUrl'] ?? '',
            disableBiographyAndPhoto: data['disableBiographyAndPhoto'] ?? false,
            disableOnlyBiography: data['disableOnlyBiography'] ?? false,
          };

          studentList.push(studentData);
        });

        callback(studentList);
      } catch (error) {
        if (errorCallback) {
          errorCallback(error as Error);
        }
      }
    },
    errorCallback
  );

  return unsubscribe;
};

export const getStudentData = async (uid: string, studentCode: string): Promise<Student | undefined> => {
  if (!uid) throw new Error('invalid-uid');
  if (!studentCode) throw new Error('invalid-student-code');

  try {
    const studentRef = ref(database, `${BASE_DATABASE_URL}/studentList/${studentCode}`);
    const studentData = (await get(studentRef)).val();

    if (!studentData) {
      return undefined;
    }

    const newStudentData: Student = {
      id: studentCode,
      parentUid: uid,
      schoolCode: studentData.schoolCode,
      schoolName: studentData.schoolName,
      studentCode: studentData.studentCode,
      studentName: studentData.studentName,
      studentFullname: studentData.studentFullname,
      studentClass: studentData.studentClass,
      biography: studentData.biography,
      imagePermission: studentData.imagePermission,
      photoUrl: studentData.photoUrl,
      disableBiographyAndPhoto: studentData.disableBiographyAndPhoto,
      disableOnlyBiography: studentData.disableOnlyBiography,
    };

    return newStudentData;
  } catch (err) {
    console.error(err);
  }

  return undefined;
};

export const registerStudentData = async (
  uid: string,
  studentData: Student,
  updateStudentKey?: string
): Promise<void> => {
  if (!uid) throw new Error('invalid-uid');
  if (!studentData || !studentData.studentCode) throw new Error('invalid-student-data');

  let studentKey: string = '';

  if (!updateStudentKey) {
    // const studentListRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/studentList`);
    // studentKey = push(studentListRef).key ?? '';

    const studentCode = studentData.studentCode;

    try {
      const studentRef = ref(database, `${BASE_DATABASE_URL}/studentList/${studentCode}`);
      const studentExistingData = (await get(studentRef)).exists();

      if (studentExistingData) {
        updateStudentKey = studentCode;
      }

      studentKey = studentCode;
    } catch (err) {
      console.error(err);
      studentKey = '';
    }
  } else {
    studentKey = updateStudentKey;
  }

  if (!studentKey) {
    throw new Error('error-registering-student');
  }

  const newStudentData = {
    id: studentKey,
    // parentUid: uid,
    [`${uid}`]: uid,
    schoolCode: studentData.schoolCode,
    schoolName: studentData.schoolName,
    studentCode: studentData.studentCode,
    studentName: studentData.studentName,
    studentFullname: studentData.studentFullname,
    studentClass: studentData.studentClass,
    biography: studentData.biography,
    imagePermission: studentData.imagePermission,
    photoUrl: studentData.photoUrl,
    disableBiographyAndPhoto: studentData.disableBiographyAndPhoto,
    disableOnlyBiography: studentData.disableOnlyBiography,
  };

  const basePath = `${BASE_DATABASE_URL}/studentList/${studentKey}`;
  const registerNewStudentUpdate: { [key: string]: string | boolean } = {};

  Object.keys(newStudentData).forEach((objKey) => {
    registerNewStudentUpdate[`${basePath}/${objKey}`] = newStudentData[objKey];
  });

  registerNewStudentUpdate[`${basePath}/parents/${uid}`] = uid;

  return update(ref(database), registerNewStudentUpdate);
};

export const deleteStudentData = async (uid: string, studentId: string): Promise<void> => {
  if (!uid) throw new Error('invalid-uid');
  if (!studentId) throw new Error('invalid-student-id');

  // console.log(' >>>> uid', uid, studentId);

  // delete student's data
  const studentDataToDelete = {
    [`${BASE_DATABASE_URL}/studentList/${studentId}`]: null,
  };

  // find & delete cart references
  const productListRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/carts/${uid}/products`);
  const productQueryListRef: Query = query(productListRef, orderByChild('studentId'), equalTo(studentId));

  const snapshot: DataSnapshot = await get(productQueryListRef);

  snapshot.forEach((productSnapshot) => {
    studentDataToDelete[`${BASE_DATABASE_URL}/carts/${uid}/products/${productSnapshot.key}`] = null;
  });

  // console.log(' >>>> studentDataToDelete', studentDataToDelete);

  return update(ref(database), studentDataToDelete);
};
