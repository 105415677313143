import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentDataResult } from 'services/functions';
import { RootState } from 'store/store';
import { Student } from 'types';

export interface StudentRegistrationData {
  schoolCode: string;
  schoolName: string;
  studentData: StudentDataResult | null;
  disableBiographyAndPhoto: boolean;
  disableOnlyBiography: boolean;
}

export interface BiographyData {
  biographyText: string;
  photoUrl: string;
  imagePermission: boolean;
}

interface WizardData {
  wizardStep: number;
  isEditMode: boolean;
}

export type StudentRegistrationState = StudentRegistrationData & BiographyData & WizardData;

const studentRegistrationSlice = createSlice({
  name: 'studentRegistration',
  initialState: {
    wizardStep: 0,
    isEditMode: false,
    schoolCode: '',
    schoolName: '',
    studentData: null,
    disableBiographyAndPhoto: false,
    disableOnlyBiography: false,
    biographyText: '',
    photoUrl: '',
    imagePermission: true,
  } as StudentRegistrationState,
  reducers: {
    clearStudentRegistrationData: (state) => {
      state.schoolCode = '';
      state.schoolName = '';
      state.studentData = null;
      state.disableBiographyAndPhoto = false;
      state.disableOnlyBiography = false;
      state.biographyText = '';
      state.photoUrl = '';
      state.imagePermission = true;
    },
    setWizardStep: (state, { payload: wizardStep }: PayloadAction<number>) => {
      state.wizardStep = wizardStep;
    },
    setEditMode: (state, { payload: isEditMode }: PayloadAction<boolean>) => {
      state.isEditMode = isEditMode;
    },
    setStudentRegistrationData: (
      state,
      { payload: studentRegistrationData }: PayloadAction<StudentRegistrationData>
    ) => {
      state.schoolCode = studentRegistrationData.schoolCode;
      state.schoolName = studentRegistrationData.schoolName;
      state.studentData = studentRegistrationData.studentData;
      state.disableBiographyAndPhoto = studentRegistrationData.disableBiographyAndPhoto;
      state.disableOnlyBiography = studentRegistrationData.disableOnlyBiography;
    },
    setStudentData: (state, { payload: studentData }: PayloadAction<Student>) => {
      state.schoolCode = studentData.schoolCode;
      state.schoolName = studentData.schoolName;
      state.studentData = {
        id: studentData.id,
        studentCode: studentData.studentCode,
        classCode: studentData.studentClass,
        name: studentData.studentName,
        fullName: studentData.studentFullname,
      };
      state.biographyText = studentData.biography;
      state.photoUrl = studentData.photoUrl;
      state.imagePermission = studentData.imagePermission;
      state.disableBiographyAndPhoto = studentData.disableBiographyAndPhoto;
      state.disableOnlyBiography = studentData.disableOnlyBiography;
    },
    setBiographyText: (state, { payload: biographyText }: PayloadAction<string>) => {
      state.biographyText = biographyText;
    },
    setPhotoUrl: (state, { payload: photoUrl }: PayloadAction<string>) => {
      state.photoUrl = photoUrl;
    },
    setImagePermission: (state, { payload: imagePermission }: PayloadAction<boolean>) => {
      state.imagePermission = imagePermission;
    },
    setDisableBiographyAndPhoto: (state, { payload: disableBiographyAndPhoto }: PayloadAction<boolean>) => {
      state.disableBiographyAndPhoto = disableBiographyAndPhoto;
    },
    setdisableOnlyBiography: (state, { payload: disableOnlyBiography }: PayloadAction<boolean>) => {
      state.disableOnlyBiography = disableOnlyBiography;
    },
  },
});

export const {
  clearStudentRegistrationData,
  setWizardStep,
  setEditMode,
  setStudentRegistrationData,
  setStudentData,
  setBiographyText,
  setPhotoUrl,
  setImagePermission,
  setDisableBiographyAndPhoto,
  setdisableOnlyBiography,
} = studentRegistrationSlice.actions;

export default studentRegistrationSlice.reducer;

export const selectWizardStep = (state: RootState) => state.studentRegistration.wizardStep;
export const selectEditMode = (state: RootState) => state.studentRegistration.isEditMode;
export const selectStudentRegistrationData = (state: RootState) =>
  ({
    schoolCode: state.studentRegistration.schoolCode,
    schoolName: state.studentRegistration.schoolName,
    studentData: state.studentRegistration.studentData,
    disableBiographyAndPhoto: state.studentRegistration.disableBiographyAndPhoto,
    disableOnlyBiography: state.studentRegistration.disableOnlyBiography,
  } as StudentRegistrationData);
export const selectBiographyText = (state: RootState) => state.studentRegistration.biographyText;
export const selectPhotoUrl = (state: RootState) => state.studentRegistration.photoUrl;
export const selectImagePermission = (state: RootState) => state.studentRegistration.imagePermission;
export const selectDisableBiographyAndPhoto = (state: RootState) => state.studentRegistration.disableBiographyAndPhoto;
export const selectdisableOnlyBiography = (state: RootState) => state.studentRegistration.disableOnlyBiography;
