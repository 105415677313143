import { useCallback, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Button, Col, Nav, Row, Container, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faCirclePlus, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

import { ROUTES } from 'constants/';
import { Student } from 'types';
import { useAuth } from 'hooks';

import { authService } from 'services/auth';
import { databaseService } from 'services/database';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppModal, AddStudentModal } from 'components';
import {
  clearStudentRegistrationData,
  setEditMode,
  setStudentData,
  setWizardStep,
} from 'features/studentRegistrationSlice';
import {
  selectCartState,
  selectSelectedStudent,
  selectStudentListData,
  setSelectedStudentId,
  setShowCart,
} from 'features/shoppingSlice';
import { StudentListCard } from './StudentListCard';
import { OrderHistoryDropdown } from './OrderHistoryDropdown';

import logo from 'logo.svg';
import './styles.scss';

interface AppNavbarProps {
  showStudentList: boolean;
  showNavbarIcons?: boolean;
}

export const AppNavbar = ({ showStudentList, showNavbarIcons = true }: AppNavbarProps) => {
  const dispatch = useAppDispatch();

  const { user, isLoading: isLoadingAuth } = useAuth();
  const selectedStudent = useAppSelector(selectSelectedStudent);

  const cartState = useAppSelector(selectCartState);

  const numberOfCartItems = useMemo(() => Object.keys(cartState.products).length, [cartState.products]);

  const signOut = useCallback(() => authService.signOut(), []);

  const studentListData = useAppSelector(selectStudentListData);
  const studentList = useMemo(() => studentListData.data, [studentListData]);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState({
    studentId: '',
    name: '',
  });

  const [showAddStudentModal, setShowAddStudentModal] = useState(false);

  const onAddNewStudent = useCallback(async () => {
    dispatch(clearStudentRegistrationData());
    dispatch(setEditMode(false));
    dispatch(setWizardStep(0));

    setShowAddStudentModal(true);
  }, [dispatch]);

  const onEditButtonClick = useCallback(
    (student: Student) => {
      const studentData = studentList?.find((std) => std.id === student.id);

      if (!studentData) {
        alert('Ocorreu um erro ao editar os dados do aluno.\nEntre em contato com a equipe técnica.');
      } else {
        dispatch(setStudentData(studentData));
        dispatch(setEditMode(true));

        if (studentData?.disableOnlyBiography) {
          dispatch(setWizardStep(3));
        } else {
          dispatch(setWizardStep(2));
        }

        setShowAddStudentModal(true);
      }
    },
    [dispatch, studentList]
  );

  const onDeleteButtonClick = useCallback((student: Student) => {
    setStudentToDelete({
      studentId: student.id,
      name: student.studentName,
    });
    setShowDeleteConfirm(true);
  }, []);

  const deleteStudent = useCallback(
    async (studentId: string) => {
      try {
        if (selectedStudent && selectedStudent?.id === studentId) {
          dispatch(setSelectedStudentId(null));
        }

        databaseService.deleteStudentData(user?.uid ?? '', studentId);
        setShowDeleteConfirm(false);
      } catch (error) {
        alert('Erro apagando aluno.');
        console.log(error);
      }
    },
    [user?.uid, selectedStudent, dispatch]
  );

  const showCart = useCallback(() => {
    dispatch(setShowCart(true));
  }, [dispatch]);

  return (
    <header className="header">
      <Navbar expand className="border-0">
        <Container fluid className="py-2 px-4">
          <Navbar.Brand>
            <Link to={ROUTES.HOME}>
              <img alt="" src={logo} height="48" className="d-inline-block align-top" />
            </Link>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end align-items-center" id="navbarScroll">
            <Nav>
              {showNavbarIcons && (
                <>
                  <Nav.Item className="align-self-center text-white fw-bolder me-4 d-none d-sm-block order-history-item">
                    <OrderHistoryDropdown iconSize="lg" />
                  </Nav.Item>
                  <Nav.Link className="text-white fw-bolder me-4 cart-item" onClick={showCart}>
                    <div className="position-relative">
                      <FontAwesomeIcon size="lg" icon={faCartShopping} />
                      {numberOfCartItems > 0 && (
                        <Badge
                          bg="danger"
                          className="position-absolute top-0 start-100 translate-middle rounded-circle p-1 badge-cart"
                        >
                          {numberOfCartItems}
                        </Badge>
                      )}
                    </div>
                  </Nav.Link>
                </>
              )}

              <Nav.Link className="text-white fw-bolder signout-item" onClick={signOut}>
                <FontAwesomeIcon icon={faRightFromBracket} className="me-1" /> Sair
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {showStudentList && (
        <Container fluid className="px-4 pt-2 pb-5">
          <Row>
            <Col xs={12} sm={4} lg={2} className="order-sm-2 order-1 text-end">
              <Button
                variant="light"
                size="sm"
                className="text-primary mb-3 p-2 add-student-button"
                onClick={onAddNewStudent}
              >
                <FontAwesomeIcon icon={faCirclePlus} /> Adicionar Aluno
              </Button>
            </Col>

            <div className="w-100 d-sm-none d-inline"></div>

            <Col xs={12} sm={8} lg={10} className="order-sm-1 order-2">
              <Container fluid className="d-flex align-items-center px-0">
                <StudentListCard
                  isLoadingAuth={isLoadingAuth}
                  isLoadingData={studentListData.isLoading}
                  errorLoadingData={studentListData.isError}
                  studentList={studentList ?? []}
                  onEditClick={onEditButtonClick}
                  onDeleteClick={onDeleteButtonClick}
                />
              </Container>
            </Col>
          </Row>
        </Container>
      )}

      <AppModal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        title="Excluir aluno"
        buttons={[
          { label: 'Sim', className: 'text-white', onClick: () => deleteStudent(studentToDelete.studentId) },
          {
            label: 'Não',
            variant: 'danger',
            className: 'text-white',
            onClick: () => setShowDeleteConfirm(false),
          },
        ]}
      >
        <p>
          Você tem certeza que deseja excluir o aluno <strong>"{studentToDelete.name}"</strong>?
        </p>
        <p>Todos os livros em seu carrinho deste aluno também serão removidos.</p>
      </AppModal>

      <AddStudentModal show={showAddStudentModal} onHide={() => setShowAddStudentModal(false)} />
    </header>
  );
};
