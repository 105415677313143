import { useCallback, useEffect } from 'react';
import { Button, Col, Container, Form } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';
import { FieldValues, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectBiographyText,
  selectEditMode,
  selectStudentRegistrationData,
  setBiographyText,
} from 'features/studentRegistrationSlice';

export const BiographyStudent = () => {
  const dispatch = useAppDispatch();
  const { schoolCode, schoolName, studentData } = useAppSelector(selectStudentRegistrationData);
  const biographyText = useAppSelector(selectBiographyText);
  const isEditMode = useAppSelector(selectEditMode);

  const { previousStep, nextStep, goToStep } = useWizard();

  const DEFAULT_BIOGRAPHY =
    'TEXTO EXEMPLO: NOME DO SEU PEQUENO, nascido(a) em DATA DE NASCIMENTO em CIDADE. Adora conversar, descobrir coisas e a curiosidade move suas brincadeiras. Filho(a) do  meio num grupo de três irmãos. Muito querido(a) por todos ao seu redor, cativa e aproxima aqueles que lhe conhecem pelo amor e carisma que exala.';

  const { register, handleSubmit } = useForm({
    defaultValues: { biographyText: biographyText !== '' ? biographyText : '' },
  });

  useEffect(() => {
    if (!studentData || !schoolCode || !schoolName) {
      goToStep(0);
    }
  }, [studentData, schoolCode, schoolName, goToStep]);

  const handleNextSubmitBiographyText = useCallback(
    ({ biographyText }: FieldValues) => {
      dispatch(setBiographyText(biographyText));
      nextStep();
    },
    [dispatch, nextStep]
  );

  const handlePrevSubmitBiographyText = useCallback(
    ({ biographyText }: FieldValues) => {
      dispatch(setBiographyText(biographyText));
      previousStep();
    },
    [dispatch, previousStep]
  );

  return (
    <Col lg={8}>
      <Container>
        <h2>Biografia</h2>
        <p>
          Escreva um texto bem bonito sobre o seu pequeno grande autor (500 caracteres) - você poderá editar esse texto
          depois:
        </p>
        {/* <blockquote className={styles['example-book']}>
          Exemplo: <strong>NOME DO SEU PEQUENO</strong>, nascido(a) em <strong>DATA DE NASCIMENTO</strong> em{' '}
          <strong>CIDADE</strong>. Adora conversar, descobrir coisas e a curiosidade move suas brincadeiras. Filho(a) do
          meio num grupo de três irmãos. Muito querido(a) por todos ao seu redor, cativa e aproxima aqueles que lhe
          conhecem pelo amor e carisma que exala.
        </blockquote> */}

        <Form onSubmit={handleSubmit(handleNextSubmitBiographyText)}>
          <Form.Control
            as="textarea"
            {...register('biographyText')}
            placeholder={DEFAULT_BIOGRAPHY}
            rows={5}
            maxLength={500}
          />

          <div className="my-4 mx-0">
            <Button
              variant="light"
              className="me-5 text-primary"
              type="button"
              onClick={handleSubmit(handlePrevSubmitBiographyText)}
              disabled={isEditMode}
            >
              Voltar
            </Button>
            <Button variant="light" className="text-primary" type="submit">
              Confirmar
            </Button>
          </div>
        </Form>
      </Container>
    </Col>
  );
};
