import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/';
import { useAuth } from 'hooks';
import { useAppDispatch } from 'store/hooks';
import { setAccountInfo, setAuthenticatedUser, setIsLoading } from 'features/authSlice';
import { databaseService } from 'services/database';
import { authService } from 'services/auth';

import { AppLoader, AppNavbar } from 'components';
import { OperationError } from 'view/pages/OperationError';

export const PrivateRoute = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isAuthenticated, user, isLoading } = useAuth();

  useEffect(() => {
    let unsubscribeDatabase: () => void | undefined;

    const unsubscribeAuth = authService.onAuthStateChanged((user) => {
      if (user) {
        unsubscribeDatabase = databaseService.listenAccountInfo(user.uid, (accountInfo) => {
          dispatch(setAccountInfo(accountInfo));
        });
      }

      dispatch(setAuthenticatedUser(user));
      dispatch(setIsLoading(false));
    });

    return () => {
      unsubscribeDatabase?.();
      unsubscribeAuth?.();
    };
  }, [dispatch]);

  // the auth state is loading...
  if (isLoading) {
    return <AppLoader />;
  }

  const uidDashCount = (user?.uid.match(/-/g) ?? []).length;

  // the user is authorized, but he/she is an ImaginaWeb user
  if (isAuthenticated && user && uidDashCount >= 3) {
    return (
      <div className="main-layout">
        <AppNavbar showStudentList={false} showNavbarIcons={false} />
        <main className="h-100 py-5">
          <OperationError
            title="Não é possível acessar este sistema com o usuário informado"
            body={
              <>
                <p>
                  Você está tentando acessar este sistema com um usuário do ImaginaWeb. Essas contas não são válidas
                  neste sistema.
                </p>
                <p>
                  Você deverá sair do sistema e se cadastrar com um email pessoal para realizar as compras. Em caso de
                  dúvida, entre em contato com a equipe da{' '}
                  <a target="_blank" href="https://www.imaginakids.com.br/" rel="noreferrer">
                    ImaginaKIDS
                  </a>
                </p>
              </>
            }
          />
        </main>
      </div>
    );
  }

  // the user is authorized, show the route
  if (isAuthenticated && user) {
    return <Outlet />;
  }

  // otherwise, if the user is not authorized, redirect to the login page
  return <Navigate to={ROUTES.LOGIN} replace state={{ from: location }} />;
};
