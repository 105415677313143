import { useCallback, useEffect } from 'react';
import { Button, Card, Col, Container } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';

import { useAppSelector } from 'store/hooks';
import { selectStudentRegistrationData } from 'features/studentRegistrationSlice';
import { useAuth } from 'hooks';
import { Student } from 'types';
import { databaseService } from 'services/database';

interface ConfirmStudentProps {
  closeModalDisabledBiography?: () => void;
}

export const ConfirmStudent = ({ closeModalDisabledBiography }: ConfirmStudentProps) => {
  const { user } = useAuth();

  const { schoolCode, schoolName, studentData, disableBiographyAndPhoto, disableOnlyBiography } =
    useAppSelector(selectStudentRegistrationData);

  const { previousStep, nextStep, goToStep } = useWizard();

  useEffect(() => {
    if (!studentData || !schoolCode || !schoolName) {
      goToStep(0);
    }
  }, [studentData, schoolCode, schoolName, goToStep]);

  const confirmSaveDisabledBiography = useCallback(() => {
    const confirmMessage = `Você tem certeza que deseja adicionar o(a) aluno(a) "${studentData?.fullName}"`;

    if (window.confirm(confirmMessage)) {
      try {
        const studentRegisterData: Student = {
          id: studentData?.id ?? '',
          parentUid: user?.uid ?? '',
          schoolCode,
          schoolName,
          studentCode: studentData?.studentCode ?? '',
          studentName: studentData?.name ?? '',
          studentFullname: studentData?.fullName ?? '',
          studentClass: studentData?.classCode ?? '',
          imagePermission: false,
          biography: '',
          photoUrl: '',
          disableBiographyAndPhoto: disableBiographyAndPhoto ?? false,
          disableOnlyBiography: disableOnlyBiography ?? false,
        };

        databaseService.registerStudentData(user?.uid ?? '', studentRegisterData);

        closeModalDisabledBiography?.();
      } catch (error) {
        alert('Erro salvando dados do aluno.');
        console.log(error);
      }
    }
  }, [
    schoolCode,
    schoolName,
    studentData,
    user?.uid,
    disableBiographyAndPhoto,
    disableOnlyBiography,
    closeModalDisabledBiography,
  ]);

  const onConfirmButton = useCallback(() => {
    // console.log(' >>> disableOnlyBiography', disableOnlyBiography);

    if (disableBiographyAndPhoto) {
      confirmSaveDisabledBiography();
    } else if (disableOnlyBiography) {
      goToStep(3);
    } else {
      nextStep();
    }
  }, [disableBiographyAndPhoto, disableOnlyBiography, confirmSaveDisabledBiography, goToStep, nextStep]);

  return (
    <Col>
      <Container>
        <h2>Confirmação</h2>
        <p>
          Para adicionar este aluno, informar sua biografia e adquirir livros, é necessário que você{' '}
          <span className="fw-bolder">confirme</span> os dados a seguir:
        </p>

        <Card className="col-lg-6">
          <Card.Header as="h5" className="bg-warning text-black border-0">
            Dados do aluno
          </Card.Header>
          <Card.Body className="fw-normal text-black">
            <div className="my-2">
              <span className="fw-bolder">Nome completo:</span> {studentData?.fullName}
            </div>
            <div className="my-2">
              <span className="fw-bolder">Nome da escola:</span> {schoolName}
            </div>
            <div className="my-2">
              <span className="fw-bolder">Código da turma:</span> {studentData?.classCode}
            </div>
          </Card.Body>
        </Card>

        <div className="my-4 mx-0">
          <Button variant="light" className="me-5 text-primary" type="button" onClick={previousStep}>
            Voltar
          </Button>
          <Button variant="light" className="text-primary" type="button" onClick={onConfirmButton}>
            Confirmar
          </Button>
        </div>
      </Container>
    </Col>
  );
};
