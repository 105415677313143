import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Product } from 'types';
import { useAppSelector } from 'store/hooks';
import { useAuth } from 'hooks';
import { createNewProductCart } from 'utils';
import { AppLoader } from 'components';
import { selectSelectedStudent } from 'features/shoppingSlice';
import { databaseService } from 'services/database';
import { ProductCard } from 'components/ProductCard';
import { OrderHistoryDropdown } from 'components/AppNavbar/OrderHistoryDropdown';

import guidoNoBooks from 'assets/images/guido_nobooks_found.svg';
import guidoselectStudent from 'assets/images/guido_select_student.svg';
import { Onboarding } from './Onboarding';

import './styles.scss';

interface ProductListState {
  isLoading: boolean;
  isError: boolean;
  data: Array<Product> | null;
}

const HomePage = () => {
  const { user } = useAuth();

  const selectedStudent = useAppSelector(selectSelectedStudent);

  const [startTour, setStartTour] = useState(false);

  const incompleteStudentData = useMemo(() => {
    let incomplete = false;

    if (!selectedStudent) {
      return false;
    }

    if (selectedStudent.disableOnlyBiography) {
      incomplete = !selectedStudent.photoUrl;
    }

    if (!selectedStudent.disableBiographyAndPhoto && !selectedStudent.disableOnlyBiography) {
      incomplete = !selectedStudent.biography || !selectedStudent.photoUrl;
    }

    return incomplete;
  }, [selectedStudent]);

  const [productList, setProductList] = useState<ProductListState>({
    isLoading: true,
    isError: false,
    data: null,
  });

  useEffect(() => {
    const loadProductList = async (schoolCode: string) => {
      setProductList({ isLoading: true, isError: false, data: null });

      try {
        const plist = await databaseService.getAvailableProductList(schoolCode);

        setProductList({ isLoading: false, isError: false, data: plist });
      } catch (error) {
        console.log(error);
        setProductList({ isLoading: false, isError: true, data: null });
      }

      setStartTour(true);
    };

    if (selectedStudent?.schoolCode) {
      loadProductList(selectedStudent?.schoolCode ?? '');
    } else {
      setProductList({ isLoading: false, isError: false, data: null });
    }
  }, [selectedStudent?.schoolCode]);

  const onAddProductCart = useCallback(
    async (productCode: string, amount: number): Promise<boolean> => {
      if (!user) {
        alert('Erro acessando informações do usuário. Recarregue a página e tente realizar a operação novamente.');
        return false;
      }

      if (!selectedStudent) {
        alert(
          'Erro acessando informações do aluno selecionado. Recarregue a página e tente realizar a operação novamente.'
        );
        return false;
      }

      const product = productList.data?.find((prd) => prd.code === productCode);
      if (!product) {
        alert('Erro acessando informações do produto. Recarregue a página e tente realizar a operação novamente.');
        return false;
      }

      try {
        await databaseService.addProductToCart(
          user.uid,
          createNewProductCart(user.uid, product, amount, selectedStudent),
          new Date()
        );

        return true;
      } catch (error) {
        console.log(error);
        alert(
          'Ocorreu um erro ao adicionar o produto no carrinho. Recarregue a página e tente realizar a operação novamente.'
        );

        return false;
      }
    },
    [user, selectedStudent, productList.data]
  );

  const productListContent = useMemo(() => {
    if (!selectedStudent) {
      return (
        <Container className="text-center py-2 px-md-15">
          <Row>
            <Col>
              <h3 className="fw-bold p-0 m-0 my-6">Selecione um aluno para começar a comprar livros!</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src={guidoselectStudent}
                alt="Pinguim apontando para cima"
                className="p-0 m-0 mb-6 information-image"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} lg={8}>
              <h5 className="p-0 m-0 mb-6">
                Para poder comprar livros de um aluno, é necessário cadastrá-lo no botão "Adicionar aluno" e
                selecioná-lo no menu no topo da página
              </h5>
            </Col>
          </Row>
        </Container>
      );
    }

    if (!productList.data || productList.data?.length === 0) {
      return (
        <Container className="text-center py-2 px-md-15">
          <Row>
            <Col>
              <h3 className="fw-bold p-0 m-0 mb-2">Não há produtos disponíveis para venda em sua escola!</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={guidoNoBooks} alt="Pinguim procurando livros" className="p-0 m-0 mb-4 information-image" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} lg={8}>
              <h5 className="p-0 m-0">Talvez o prazo de compra já esteja encerrado!</h5>
              <h5 className="p-0 m-0">
                Entre em contato com sua escola ou com a equipe da{' '}
                <a target="_blank" href="https://www.imaginakids.com.br/" rel="noreferrer">
                  ImaginaKIDS
                </a>{' '}
                para mais informações.
              </h5>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <>
        <h3 className="mb-5 fw-bolder">Nossos livros</h3>
        <Row xs={1} sm={2} md={3} lg={4} className="g-6 product-container">
          {productList.data?.map((product) => (
            <Col key={product.code} className="product-col">
              <ProductCard
                productCode={product.code}
                title={product.name}
                bookType={product.bookType}
                imageSrc={product.photoUrl}
                imageAltText={product.name}
                productDescription={product.productDescription}
                salesDescription={product.salesDescription}
                amount={product.bookAmount}
                price={product.price}
                badge={product.badge}
                incompleteStudentData={incompleteStudentData}
                onAddProductCart={onAddProductCart}
              />
            </Col>
          ))}
        </Row>
        <button className="floating-button">
          <OrderHistoryDropdown menuAlignment="end" drop="up" iconSize="lg" />
        </button>
      </>
    );
  }, [selectedStudent, productList.data, incompleteStudentData, onAddProductCart]);

  if (!user || productList.isLoading) {
    return <AppLoader />;
  }

  return (
    <div className="div-center">
      <section className="row">
        <Col className="p-8">
          <Container className="product-list">{productListContent}</Container>
          <Onboarding start={startTour} />
        </Col>
      </section>
    </div>
  );
};

export default HomePage;
